<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de sala - Editar</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
           
                <b-col md="3">
                  <b-form-group label="Nombre :">
                    <b-form-input type="text" v-model="room.name"></b-form-input>
                    <small v-if="errors.name" class="form-text text-danger" >Ingrese un nombre</small>
                  </b-form-group>
                </b-col>
           
                <b-col md="7">
                  <b-form-group label="Descripción :" >
                    <b-form-input type="text" v-model="room.description" ></b-form-input>
                  </b-form-group>
                </b-col>
          
                <b-col md="2">
                  <b-form-group label="Estado :">
                    <b-form-select  v-model="room.state" :options="state"></b-form-select>
                  </b-form-group>
                </b-col>

                <b-col md="5"></b-col>
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import ApiQuery from "@/assets/js/APIQuery";
import LoadingComponent from './../pages/Loading'

export default {
  name: "roomEdit",
  props: ["id_room"],
  components:{
    vSelect,
    Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  
  data() {
    return {
      module:'Room',
      role:3,
      isLoading: false,
      room: {
        id_room: "",
        name: "",
        description: "",
        state: 1,
      },
      state:[
        {value: 1 , text : 'Activo'},
        {value: 0 , text : 'Inactivo'},
      ],
      //errors
      errors: {
        name: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewRoom();
  },
  methods: {
    ViewRoom,
    EditRoom,
    Validate,
  
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ViewRoom() {
  let me = this;
  let id_room = je.decrypt(me.id_room);
  let url = me.url_base + "room/view/" + id_room;
  axios({
    method: "GET",
    url: url,
    headers: {token: me.token, module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.room.id_room = response.data.result.id_room;
        me.room.name = response.data.result.name;
        me.room.description = response.data.result.description;
        me.room.state = response.data.result.state;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

//editar usuario
function EditRoom() {
  let me = this;
  let url = me.url_base + "room/edit";
  let data = me.room;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: {"Content-Type": "application/json",token: me.token, module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

//validacion de formulario
function Validate() {

  this.errors.name = this.room.name.length == 0 ? true : false;
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate = false; }

  if (!this.validate) {
    Swal.fire({
      title: "Esta seguro de modificar la sala?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Estoy de acuerdo!",
    }).then((result) => {
      if (result.value) {
        this.EditRoom();
      }
    });

  }

}
</script>
